<template>
  <div class="header tw-flex tw-items-center">
    <img src="../assets/images/home_icon.png" alt="" style="margin-right:16px;" />
    <p class="header_label tw-text-16px tw-font-bold">{{title}}</p>
  </div>
</template>

<script>
export default {
  props: {
    title: String
  }
}
</script>

<style lang="scss" scoped>
.header {
  &_label {
    line-height: 22px;
    color: #303133;
  }
}
</style>
