/*
  *******************
      注册全局组件
  *******************
*/
import Vue from 'vue'

import detailHeader from '../components/detail-header.vue'
import pageHeader from '../components/page-header.vue'
import userAvatar from '../components/user-avatar.vue'
import uploadFiles from '../components/upload-file/upload-files.vue'
import infoHeader from '../components/info-header.vue'
import info from '../components/info/index.vue'
import infoItem from '../components/info/info-item.vue'

Vue.component('detailHeader', detailHeader)
Vue.component('pageHeader', pageHeader)
Vue.component('userAvatar', userAvatar)
Vue.component('uploadFiles', uploadFiles)
Vue.component('infoHeader', infoHeader)
Vue.component('info', info)
Vue.component('infoItem', infoItem)
