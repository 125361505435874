// 全局混合
import Vue from 'vue'
import globalVar from '../configs/globalVar'
import staticData from '../configs/data'

const mixin = {
  computed: {
    // 全局变量
    globalVar () {
      return globalVar
    },
    // 静态数据
    staticData () {
      return staticData
    },
    // 当前用户信息
    userInfo () {
      return this.$store.state.userInfo
    },
    // 当前用户的机构信息 如果不存在机构 则未进行挂靠或入驻
    organization () {
      return this.userInfo.organization ? this.userInfo.organization : {}
    },
    // 当前角色
    role () {
      return this.userInfo.role
    },
    // 当前用户id
    curUserId () {
      return this.userInfo.id
    },
    // 是否是普通用户
    isCommonUser () {
      return this.role === globalVar.USER_ROLE_0
    },
    // 是否是服务用户
    isServiceOrg () {
      return this.role === globalVar.USER_ROLE_1
    },
    // 是否是服务集成商用户
    isServiceAggregator () {
      return this.role === globalVar.USER_ROLE_2
    }
  }
}

Vue.mixin(mixin)
