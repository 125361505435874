<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'app',
  created () {
    this.$store.dispatch('getTechnicalList')
    this.$store.dispatch('getIndustrySectorList')
    this.$store.dispatch('getServiceTypeOptions')
  }
}
</script>

<style lang="scss">
@import './assets/styles/index.scss';
@import url('//at.alicdn.com/t/font_2324264_z5r0ei02ywb.css');

#app {
  width: 100%;
  height: 100%;
}
</style>
