import Vue from 'vue'
import vuescroll from 'vuescroll/dist/vuescroll-native'

// 你可以在这里设置全局配置
Vue.use(vuescroll, {
  ops: {
    vuescroll: {},
    scrollPanel: {},
    rail: {},
    bar: {
      background: '#DDDEE0'
    },
    scrollButton: {}
  }, // 在这里设置全局默认配置
  name: 'vueScroll' // 在这里自定义组件名字，默认是vueScroll
})
