<template>
  <el-upload
    :class="[customClassName]"
    ref="uploadFiles"
    :action="action"
    :headers="headers"
    :multiple="false"
    :limit="limit"
    :accept="accept"
    :on-remove="removeFile"
    :on-success="successUpload"
    :on-error="errorUpload"
    :on-exceed="handleExceed"
    :on-preview="handlePreview"
    :before-upload="beforeUpload"
    :list-type="listType"
    :file-list="fileList"
    :show-file-list="showFileList">
    <template v-if="canUpload">
      <template v-if="listType === 'custom'">
        <slot></slot>
      </template>
      <el-button
        size="small"
        :type="btnType"
        v-else-if="listType !== 'picture-card'"
        icon="el-icon-upload"
        :loading="uploadBtnLoading">
        {{ btnTxt }}
      </el-button>
      <i v-else slot="default" class="el-icon-plus"></i>
      <p v-if="showTip" slot="tip" class="upload_tips tw-text-12px tw-text-secondary-text tw-leading-20px">{{ tips }}</p>
    </template>
  </el-upload>
</template>
<script>
import cookie from '@/configs/cookie'
import * as api from '@/api'
export default {
  data () {
    return {
      // 上传按钮loading
      uploadBtnLoading: false
    }
  },
  props: {
    // 限制数量
    limit: {
      type: Number,
      default: 10
    },
    // 是否显示上传列表
    showFileList: {
      type: Boolean,
      default: true
    },
    // 文件列表
    fileList: {
      type: Array,
      default: () => {
        return []
      }
    },
    // 接受的文件类型
    accept: {
      type: String,
      default: () => {
        return (
          //  pdf
          'application/pdf,' +
          //  xls
          'application/vnd.ms-excel,' +
          //  xlsx
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,' +
          //  doc
          'application/msword,' +
          //  docx
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document,' +
          //  pptx
          'application/vnd.openxmlformats-officedocument.presentationml.presentation'
        )
      }
    },
    // 文件最大大小（单位M）
    maxSize: {
      type: Number,
      default: 10
    },
    // 提示信息
    tips: {
      type: String,
      default: '允许上传pdf,xlsx,xls,docx,doc pptx格式的文件，单个文件小于10M'
    },
    // 是否显示提示
    showTip: {
      type: Boolean,
      default: true
    },
    // 文件数量超出的提示语
    exceedErrorMessage: {
      type: String,
      default: '上传失败，文件数量过多！'
    },
    // 列表类型 text/picture/picture-card
    listType: {
      type: String,
      default: 'text'
    },
    // 是否可以上传
    canUpload: {
      type: Boolean,
      default: true
    },
    // 是否只读
    readonly: {
      type: Boolean,
      default: false
    },
    // 按钮类型
    btnType: {
      type: String,
      default: 'primary'
    },
    // 是否为朴素按钮
    btnPlain: {
      type: Boolean,
      default: false
    },
    // 按钮文字
    btnTxt: {
      type: String,
      default: '点击上传'
    },
    // 自定义类名
    customClassName: {
      type: String,
      default: ''
    }
  },
  computed: {
    $_fileList: {
      get () {
        return this.fileList
      },
      set (newFileList) {
        this.$emit('update:fileList', newFileList)
      }
    },
    // 上传地址
    action () {
      return api.uploadURL
    },
    // 上传携带凭证
    headers () {
      const token = cookie.get(this.globalVar.WEB_TOKEN)
      return {
        Authorization: 'Bearer ' + token
      }
    }
  },
  methods: {
    // 上传成功
    successUpload (res, file, fileList) {
      this.uploadBtnLoading = false
      console.log(res)
      if (res.code === 0) {
        if (!this.readonly) {
          /**
           * uid给el做key,
           * name: 给el做名字
           */
          this.$_fileList.push({
            uid: file.uid,
            name: file.name,
            ...res.data
          })
        }

        // 执行上传成功后的回调
        this.$emit('successUpload', file, res)
      } else {
        this.$message.error('上传文件出错')
      }
    },
    // 文件上传之前
    beforeUpload (file) {
      this.uploadBtnLoading = true
      if (this.accept && this.$refs.uploadFiles.accept.search(file.type) === -1) {
        this.uploadBtnLoading = false
        this.$message.warning('上传失败，文件格式不正确！')
        return false
      }
      if (file.size / 1024 / 1024 > this.maxSize) {
        this.uploadBtnLoading = false
        this.$message.warning(`上传失败，文件大于${this.maxSize}M`)
        return false
      }
    },
    // 上传文件出错
    errorUpload (error, file, fileList) {
      this.uploadBtnLoading = false
      console.log(error)
      this.$message.error('文件上传出错!')
    },
    // 删除文件
    removeFile (file, fileList) {
      const index = this.$_fileList.findIndex(item => {
        return item.uid === file.uid
      })
      this.$_fileList.splice(index, 1)
      // 执行删除的回调
      this.$emit('successRemove')
    },
    // 文件超出个数限制
    handleExceed (files, fileList) {
      this.uploadBtnLoading = false
      this.$message.warning(this.exceedErrorMessage)
    },
    // 点击文件列表中的文件
    handlePreview (file) {
      const downloadUrl = file.downloadUrl
      // const a = document.createElement('a')
      // a.download = file.origin_name
      // a.href = file.url
      // a.click()
      if (downloadUrl) {
        window.open(downloadUrl)
      }
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
