<template>
  <div class="avatar" :style="avatarStyle">
    <img v-if="url" :src="url" style="object-fit: cover;" alt="">
    <img v-else src="../assets/images/default_avatar.png" alt="">
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      default: 32
    },
    url: {
      type: String
    }
  },
  computed: {
    avatarStyle () {
      return {
        width: `${this.size}px`,
        height: `${this.size}px`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
