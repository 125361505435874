import api from '@/configs/api'

/**
 * 文件上传地址
 */
export const uploadURL = api.baseUrl + 'api/v1/uploads'

/**
 * 文件上传
 * @param {*} data
 */
export const uploadFile = (data) => api.upload('api/v1/uploads', data)

/**
 * 上传base64
 * @param {*} data
 * @returns
 */
export const uploadFileBase64 = (data) => api.post('api/v1/uploads/base64', data)

/**
 * 登录
 * @param {*} data
 */
export const login = (data) => api.post('api/v1/auth/login', data)

/**
 * 注册
 * @param {*} data
 */
export const register = (data) => api.post('api/v1/auth/register', data)

/**
 * 机构入驻（非法人）
 * @param {*} data
 */
export const uncorporateRegister = (data) => api.post('api/v1/organization/register/virtual', data)

/**
 * 机构入驻（法人）
 * @param {*} data
 */
export const corporateRegister = (data) => api.post('api/v1/organization/register', data)

/**
 * 机构入驻（个人挂靠）
 * @param {*} data
 */
export const personOrgRegister = (data) => api.post('api/v1/organization/depend', data)

/**
 * 通过关键字来获取下拉列表
 * @param {*} data
 */
export const getKeywordsDownOrgList = (data) => api.get('api/v1/organization/down_list', data)

/**
 * 集成商认证（机构管理员）
 * @param {*} data
 */
export const orgAdminAggregatorRegister = (data) => api.post('api/v1/organization/certification/legal_person', data)

/**
 * 集成商认证（个人）
 * @param {*} data
 * @returns
 */
export const orgPersonAggregatorRegister = (data) => api.post('api/v1/organization/certification', data)

/**
 * 服务机构注册 （机构入驻）
 * 一般机构申请成为服务机构
 * @param {*} data
 */
export const serviceOrgRegister = (data) => api.post('api/v1/organization/register', data)

/**
 * 集成商认证
 * @param {*} data
 */
export const orgCertification = (data) => api.post('api/v1/organization/certification', data)

/**
 * 获取机构信息
 * @param {*} organizationId
 * @param {*} data
 */
export const getOrgRzAuditDetail = (organizationId, data) => api.get(`api/v1/organization/${organizationId}/info`, data)

/**
 * 一般机构注册
 * @param {*} data
 */
export const generalOrgRegister = (data) => api.post('api/v1/auth/register/general', data)

/**
 * 忘记密码
 * @param {*} data
 */
export const forgetPassword = (data) => api.post('api/v1/auth/forget', data)

/**
 * 发送短信
 * 获取验证码
 * @param {*} data
 */
export const sendSmsCode = (data) => api.post('api/v1/notify/sms/send_code', data)

/**
 * 获取当前用户信息
 * @param {*} data
 */
export const getUserInfo = (data) => api.get('api/v1/user/me', data)

/**
 * 编辑用户基本资料
 * @param {*} data
 */
export const updateUserInfo = (data) => api.put('api/v1/user/me', data)

/**
 * 获取资讯推荐列表
 * @param {*} data
 */
export const getIndexInfoList = (data) => api.get('api/v1/news/recommend', data)

/**
 * 获取栏目列表
 * @param {*} data
 */
export const getInfoColumn = (data) => api.get('api/v1/news/column', data)
/**
 * 获取某一栏目下的列表
 * @param {*} id
 * @param {*} data
 */
export const getInfoList = (id, data) => api.get(`api/v1/news/${id}/list`, data)

/**
 * 删除某一条内容
 * @param {*} id
 * @param {*} data
 */
export const deleteInfo = (id, data) => api.remove(`api/v1/news/${id}`, data)

/**
 * 获取资讯详情
 * @param {*} id
 * @param {*} data
 */
export const getInfoDetail = (id, data) => api.get(`api/v1/news/${id}/details`, data)

/**
 * 给某一栏目添加一个新记录
 * @param {*} data
 */
export const addInfo = (data) => api.post('api/v1/news', data)

/**
 * 编辑某个内容
 * @param {*} data
 */
export const updateInfo = (id, data) => api.put(`admin/api/v1/news/${id}`, data)

/**
 * 获取推荐需求列表
 * @param {*} data
 */
export const getRecommendRequireList = (data) => api.get('api/v1/requirements/recommend', data)

/**
 * 获取服务类型下拉列表
 * 可以传parentId获取二级服务列表
 * 也可以传入level 获取具体的层级
 * @param {*} data
 */
export const getServiceTypeOptions = (data) => api.get('admin/api/v1/service_type/downlist', data)

/**
 * 获取行业领域列表
 * @param {*} data
 */
export const getIndustrySectorList = (data) => api.get('api/v1/industry/list', data)

/**
 * 获取技术领域列表
 * @param {*} data
 */
export const getTechnicalList = (data) => api.get('api/v1/technical/list', data)

/**
 * 创建需求
 * @param {*} data
 */
export const createRequirement = (data) => api.post('api/v1/requirement', data)

/**
 * 创建组合需求
 * @param {*} data
 */
export const createRequirementChain = (data) => api.post('api/v1/requirement/group', data)

/**
 * @deprecated
 * 获取我的需求列表
 * @param {*} data
 */
export const getRequirementList = (data) => api.get('api/v1/requirement/list', data)

/**
 * 获取我发布的需求列表
 * @param {*} data
 */
export const getPublisherRequire = (data) => api.get('api/v1/requirements/publisher', data)

/**
 * 获取被分配的需求列表
 * @param {*} data
 */
export const getDistributorRequire = (data) => api.get('api/v1/requirements/distributor', data)

/**
 * 获取我接受的需求列表
 * @param {*} data
 */
export const getReceiverRequire = (data) => api.get('api/v1/requirements/receiver', data)

/**
 * 撤销我的需求
 * @param {*} id
 * @param {*} data
 */
export const revokeMyRequire = (id, data) => api.put(`api/v1/requirement/${id}/revoke`, data)

/**
 * 更改需求状态
 * @param {*} id
 * @param {*} data
 */
export const updateRequireStatus = (id, data) => api.put(`api/v1/requirement/${id}/status`, data)

/**
 * 获取服务推荐列表
 * @param {*} data
 */
export const getServiceRecommendList = (data) => api.get('api/v1/service/recommend', data)

/**
 * 创建服务
 * @param {*} data
 */
export const createService = (data) => api.post('api/v1/service', data)

/**
 * 获取我的服务列表
 * @param {*} data
 */
export const getMyServiceList = (data) => api.get('api/v1/service/mylist', data)

/**
 * 下架我的服务
 * @param {*} serviceId
 * @param {*} data
 */
export const revokeMyService = (serviceId, data) => api.put(`api/v1/service/${serviceId}/revoke`, data)

/**
 * 通过服务类型id 来获取表单
 * @param {*} data
 */
export const getFormByService = (id, data) => api.get(`admin/api/v1/form/${id}/by_service`, data)

/**
 * 根据条件筛选需求列表
 * 一级服务、二级服务、技术领域
 * @param {*} data
 */
export const searchRequire = (data) => api.get('api/v1/requirements/search', data)

/**
 * 获取服务类型两级结构的列表
 * @param {*} data
 */
export const getServiceTypeCheckList = (data) => api.get('admin/api/v1/service_type/ladderlist', data)

/**
 * 根据条件筛选服务列表
 * 一级服务、二级服务、技术领域、地区
 * @param {*} data
 */
export const searchService = (data) => api.get('api/v1/service/list', data)

/**
 * 获取我的服务链列表
 * @param {*} data
 */
export const getMyServiceChainList = (data) => api.get('api/v1/service_chain/list', data)

/**
 * 发布一条服务链
 * @param {*} data
 */
export const createServiceChain = (data) => api.post('api/v1/service_chain', data)

/**
 * 修改服务链
 * @param {*} id
 * @param {*} data
 */
export const editServiceChain = (id, data) => api.put(`api/v1/service_chain/${id}`, data)

/**
 * 禁用、启用服务链
 * @param {*} id
 * @param {*} data
 */
export const toggleServiceChainStatus = (id, data) => api.put(`api/v1/service_chain/${id}/enabled`, data)

/**
 * 获取服务链详情
 * @param {*} chainId
 * @param {*} data
 */
export const getServiceChainDetail = (chainId, data) => api.get(`api/v1/service_chain/${chainId}/details`, data)

/**
 * 提交服务订单
 * @param {*} data
 */
export const createServiceOrder = (data) => api.post('api/v1/service_order', data)

/**
 * 提交服务链订单
 * @param {*} data
 */
export const createServiceChainOrder = (data) => api.post('api/v1/service_chain_order', data)

/**
 * 获取买家订单列表
 * @param {*} data
 */
export const getBuyerOrderList = (data) => api.get('api/v1/service_order/buyer/list', data)

/**
 * 获取卖家订单列表
 * @param {*} data
 */
export const getSellerOrderList = (data) => api.get('api/v1/service_order/seller/list', data)

/**
 * 获取订单详情
 * @param {*} id
 * @param {*} data
 */
export const getOrderDetail = (id, data) => api.get(`api/v1/service_order/${id}/info`, data)

/**
 *  获取订单中的服务信息
 * @param {*} id
 * @param {*} data
 */
export const getOrderServiceDetail = (id, data) => api.get(`api/v1/service_order/${id}/details`, data)

/**
 * 页面活跃度
 * @param {*} data
 */
export const pageActivity = (data) => api.get('api/v1/logs/action/page', data)

/**
 * 获取服务详情
 * @param {*} id
 * @param {*} data
 */
export const getServiceDetail = (id, data) => api.get(`api/v1/service/${id}/details`, data)

/**
 * 修改当前机构密码
 * @param {*} data
 */
export const updateOrgPassword = (data) => api.put('api/v1/user/reset', data)

/**
 * 获取需求
 * @param {*} id
 * @param {*} data
 */
export const getRequireDetail = (id, data) => api.get(`api/v1/requirement/${id}/details`, data)

/**
 * 管理端获取资源详情
 * @param {*} id
 * @param {*} data
 */
export const getRequireFormDetail = (id, data) => api.get(`api/v1/requirement/${id}/result`, data)

/**
 * 根据服务类型获取服务列表
 * @param {*} data
 */
export const getServiceListByType = (data) => api.get('api/v1/service/list/by_type', data)

/**
 * 获取指定机构服务列表
 * @param {*} id
 */
export const getOrgServiceList = (id, data) => api.get(`api/v1/service/${id}/organization/list`, data)

/**
 * 获取服务团队列表
 * @param {*} id
 * @param {*} data
 */
export const getOrgTeamList = (id, data) => api.get(`api/v1/service_team/${id}/list`, data)

/**
 * 获取提供商列表
 * @param {*} id
 * @param {*} data
 */
export const getOrgProviderList = (id, data) => api.get(`api/v1/service_provider/${id}/list`, data)

/**
 * 更改机构的简介
 * @param {*} id
 * @param {*} data
 */
export const updateOrgDescription = (data) => api.put('api/v1/organization/description', data)

/**
 * 添加机构团队成员
 * @param {*} data
 */
export const addOrgTeamMember = (data) => api.post('api/v1/service_team', data)

/**
 * 删除服务团队成员
 * @param {*} id
 * @param {*} data
 */
export const deleteOrgTeamMember = (id, data) => api.remove(`api/v1/service_team/${id}`, data)

/**
 * 添加服务提供商
 * @param {*} data
 */
export const addOrgProvider = (data) => api.post('api/v1/service_provider', data)

/**
 * 删除服务提供商
 * @param {*} id
 * @param {*} data
 */
export const deleteOrgProvider = (id, data) => api.remove(`api/v1/service_provider/${id}`, data)

/**
 * 提交流程数据
 * @param {*} data
 */
export const submitProcessData = (data) => api.post('api/v1/process/put_data', data)

/**
 * 获取机构服务记录
 * @param {*} id
 * @param {*} data
 */
export const getOrgServiceLogs = (id, data) => api.get(`api/v1/sys_log/service_log/${id}/list`, data)

/**
 * 完结指定订单
 * @param {*} id
 * @param {*} data
 */
export const finishOrder = (id, data) => api.put(`api/v1/service_order/${id}/end`, data)

/**
 * 获取第三方登录跳转地址
 * @param {*} client
 * @param {*} data
 */
export const getThirdPartyUrl = (client, data) => api.get(`api/v1/sns/redirect/${client}`, data)

/**
 * 第三方登录
 * @param {*} client
 * @param {*} data
 */
export const thirdPartyLogin = (client, data) => api.post(`api/v1/auth/sns/login/${client}`, data)

/**
 * 第三方绑定
 * @param {*} client
 * @param {*} data
 */
export const thirdPartyBind = (client, data) => api.post(`api/v1/sns/bind/${client}`, data)

/**
 * 提交订单评价
 * @param {*} orderId
 * @param {*} data
 */
export const addOrderComment = (orderId, data) => api.post(`api/v1/order/feedback/${orderId}/submit`, data)

/**
 * 获取订单评价详情
 * @param {*} orderId
 * @param {*} data
 */
export const getOrderCommentInfo = (orderId, data) => api.get(`api/v1/order/feedback/${orderId}/info`, data)

/**
 * 获取服务的反馈列表
 * @param {*} serviceId
 * @param {*} data
 */
export const getServiceCommentList = (serviceId, data) => api.get(`api/v1/order/feedback/${serviceId}/service_list`, data)

/**
 * 获取机构挂靠成员列表
 * @param {*} data
 */
export const getOrgGkList = (data) => api.get('api/v1/user/list', data)

/**
 * 机构法人转让
 * @param {*} toUserId
 * @param {*} data
 */
export const orgTransfer = (toUserId, data) => api.post(`api/v1/user/transfer/${toUserId}`, data)

/**
 * 机构管理员移除挂靠
 * @param {*} toUserId
 * @param {*} data
 */
export const removeGkFromOrg = (toUserId, data) => api.post(`api/v1/user/kicking/${toUserId}`, data)

/**
 * 获取挂靠申请列表
 * @param {*} data
 */
export const getOrgGkAuditList = (data) => api.get('api/v1/organization/depend/review/list', data)

/**
 * 同意或驳回机构挂靠申请
 * @param {*} userId
 * @param {*} data
 */
export const auditGkDetail = (userId, data) => api.put(`api/v1/organization/depend/review/${userId}`, data)

/**
 * 获取前台用户基本资料
 * @param {*} data
 */
export const getUserItemInfo = (userId, data) => api.get(`admin/api/v1/user/${userId}/details`, data)

/**
 * 获取举报类型列表
 * @param {*} data
 * @returns
 */
export const getReportTypeList = (data) => api.get('api/v1/complaint/type_list', data)

/**
 * 发起举报
 * @param {*} data
 * @returns
 */
export const createReport = (data) => api.post('api/v1/complaint', data)

/**
 * 获取举报记录列表
 * @param {*} data
 * @returns
 */
export const getReportList = (data) => api.get('api/v1/complaint/list', data)

/**
 * 获取专家团队列表
 * @param {*} data
 * @returns
 */
export const getExpertList = (data) => api.get('api/v1/expert/list', data)

/**
 * 获取专家详情
 * @param {*} data
 * @returns
 */
export const getExpertDetail = (expertId, data) => api.get(`api/v1/expert/${expertId}/details`, data)

/**
 * 发布专家
 * @param {*} data
 * @returns
 */
export const createExpert = (data) => api.post('api/v1/expert', data)

/**
 * 发布成果
 * @param {*} data
 * @returns
 */
export const createAchievement = (data) => api.post('api/v1/achievement', data)

/**
 * 获取用户报价的我发布的需求的订单列表
 * @param {*} data
 * @returns
 */
export const getUserRequireOrderList = (data) => api.get('api/v1/requirement/order_list', data)

/**
 * 获取我报价的别人发布的需求的订单列表
 * @param {*} data
 * @returns
 */
export const getMyRequireOrderList = (data) => api.get('api/v1/requirement/offer_order_list', data)

/**
 * 获取需求订单详情
 * @param {*} orderId
 * @param {*} data
 * @returns
 */
export const getRequireOrderDetail = (orderId, data) => api.get(`api/v1/requirement/order/${orderId}`, data)

/**
 * 完结需求订单
 * @param {*} orderId
 * @param {*} data
 * @returns
 */
export const finishRequireOrder = (orderId, data) => api.post(`api/v1/requirement/order/${orderId}/close`, data)

/**
 * 获取我的成果列表
 * @param {*} data
 * @returns
 */
export const getAchievementList = (data) => api.get('api/v1/achievements', data)

/**
 * 获取我的成果详情
 * @param {*} data
 * @returns
 */
export const getAchievementDetail = (achievementId, data) => api.get(`api/v1/achievement/${achievementId}`, data)

/**
 * 在资源大厅和需求详情表单中提交报价
 * @param {*} id
 * @param {*} data
 */
export const submitRequirePrice = (id, data) => api.post(`api/v1/requirement/${id}/quotation`, data)

/**
 * 确认需求报价
 * @param {*} quotationId
 * @param {*} data
 * @returns
 */
export const confirmRequirePrice = (quotationId, data) => api.put(`api/v1/requirement/quotation/${quotationId}`, data)

/**
 * 处理被分配的情况，接受或拒绝
 * @param {*} id
 * @param {*} data
 * @returns
 */
export const handleAllocate = (id, data) => api.put(`api/v1/requirement/${id}/distributor`, data)

/**
 * 上传需求诊断报告
 * @param {*} id
 * @param {*} data
 * @returns
 */
export const uploadReport = (id, data) => api.post(`api/v1/requirement/${id}/upreport`, data)

/**
 * 处理服务集成商的诊断报告，接受或拒绝
 * @param {*} id
 * @param {*} data
 * @returns
 */
export const handleReport = (id, data) => api.put(`api/v1/requirement/${id}/upreport`, data)

/**
 * 更新需求信息
 * @param {*} id
 * @param {*} data
 * @returns
 */
export const updateRequire = (id, data) => api.put(`api/v1/requirement/${id}`, data)

/**
 * 获取子需求列表
 * @param {*} id
 * @param {*} data
 * @returns
 */
export const getSubRequireList = (id, data) => api.get(`api/v1/requirements/${id}/subrequirement`, data)

/**
 * 获取服务用户、服务集成商下拉列表
 * @param {*} data
 * @returns
 */
export const getUserListByRole = (data) => api.get('api/v1/provider/downlist', data)

/**
 * 服务集成商给子需求分配服务用户
 * @param {*} id
 * @param {*} data
 * @returns
 */
export const allocateForSubRequire = (id, data) => api.post(`api/v1/requirement/${id}/provider`, data)

/*
 * 提交合同信息接口
 * @param {*} id
 * @param {*} data
 */
export const submitContractInfo = (id, data) => api.post(`api/v1/contract/${id}/params`, data)

/**
 * 提交签名文件
 * @param {*} id
 * @param {*} data
 */
export const submitSignature = (id, data) => api.post(`open/api/v1/contract/${id}/autograph`, data)

/**
 * 确认需求合同
 * @param {*} id
 * @param {*} data
 */
export const confirmContract = (id, data) => api.put(`api/v1/contract/${id}/confirm`, data)

/**
 * 获取专利列表
 * @param {*} id
 * @param {*} data
 * @returns
 */
export const getPatentList = (data) => api.get('api/v1/patent/list', data)

/**
 * 获取专利详情
 * @param {*} id
 * @param {*} data
 * @returns
 */
export const getPatentDetail = (id, data) => api.get(`api/v1/patent/${id}`, data)

/**
 * 获取论文列表
 * @param {*} id
 * @param {*} data
 * @returns
 */
export const getJournalList = (data) => api.get('api/v1/journal/list', data)

/**
 * 获取论文详情
 * @param {*} id
 * @param {*} data
 */
export const getJournalDetail = (id, data) => api.get(`api/v1/journal/${id}`, data)

/**
 * 获取专利匹配列表
 * @param {*} id
 * @param {*} data
 * @returns
 */
export const getPatentMatchList = (data) => api.get('api/v1/patent/match/list', data)

/**
 * 资源大厅搜索成果
 * @param {*} data
 * @returns
 */
export const searchAchievement = (data) => api.get('api/v1/achievement/list', data)

/**
 * 获取成果推荐列表
 * @param {*} data
 * @returns
 */
export const getAchievementRecommend = (data) => api.get('api/v1/achievement/recommend', data)

/**
 * 提供服务的用户，确认或取消服务订单
 * @param {*} id
 * @param {*} type
 * @param {*} data
 * @returns
 */
export const confirmServiceOrder = (id, type, data) => api.put(`api/v1/service_order/${id}/action/${type}`, data)
