<template>
  <div class="title">
    <p class="tw-text-16px tw-font-bold">{{title}}</p>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  color: #000;
  height: 40px;
  display: flex;
  align-items: center;
  & > p {
    position: relative;
    line-height: 22px;
    &::before {
      content: "";
      position: absolute;
      width: 3px;
      height: 20px;
      top: 50%;
      transform: translateY(-50%);
      left: -10px;
      background-color: #3473e6;
      border-radius: 1px;
    }
  }
}
</style>
