<template>
  <div class="info_item">
    <p
      class="info_item_label"
      :style="{
        width: labelWidth
      }">
      {{label}}
    </p>

    <template v-if="valueType === 'text'">
      <p class="info_item_value"> <slot>{{value}}</slot> </p>
    </template>

    <template v-if="valueType === 'image'">
      <el-image
        :style="{
          width: imageWidth,
          height: imageHeight
        }"
        :src="value"
        :fit="imageFit">
      </el-image>
    </template>

    <template v-if="valueType === 'image-list'">
      <div class="images">
        <div class="images_item" v-for="(src, index) in value" :key="index">
          <el-image
            :style="{
              width: imageWidth,
              height: imageHeight
            }"
            :src="src"
            :preview-src-list="value"
            :fit="imageFit">
          </el-image>
        </div>
      </div>
    </template>

    <template v-if="valueType === 'file-list'">
      <file-list :list="value"></file-list>
    </template>
  </div>
</template>

<script>
import fileList from '../upload-file/files-list'

export default {
  components: {
    fileList
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    labelWidth: {
      type: String,
      default: '120px'
    },
    valueType: {
      type: String,
      default: 'text',
      validator: function (value) {
        // 这个值必须匹配下列字符串中的一个
        return ['text', 'image', 'image-list', 'file-list'].indexOf(value) !== -1
      }
    },
    imageWidth: {
      type: String,
      default: '120px'
    },
    imageHeight: {
      type: String,
      default: '120px'
    },
    imageFit: {
      type: String,
      default: 'cover'
    },
    value: {
      type: [String, Array, Number],
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.info_item {
  min-height: 32px;
  margin-bottom: 22px;
  display: flex;
  &_label {
    flex: none;
    padding: 0px 10px 0px 0;
    line-height: 32px;
    color: #909399;
  }
  &_value {
    flex: 1;
    padding: 0px 0;
    line-height: 32px;
    color: #303133;
    word-break: break-all;
  }
}
.images {
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
  &_item {
    // width: 120px;
    // height: 120px;
    margin: 5px;
  }
}
</style>
