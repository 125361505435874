<template>
  <ul
    class="docs_list"
    v-if="list.length > 0">
    <li
      class="docs_list_item"
      v-for="item in list"
      :key="item.id">
      <a
        :href="item.downloadUrl"
        :download="item.originName">
        <div
          class="docs_list_item_name"
          :title="item.originName">
          <i class="el-icon-document"></i>
          {{ item.originName }}
        </div>
      </a>
    </li>
  </ul>
  <p v-else>无文件</p>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.docs_list {
  .docs_list_item {
    transition: all .5s cubic-bezier(.55,0,.1,1);
    font-size: 14px;
    color: #606266;
    line-height: 32px;
    position: relative;
    border-radius: 4px;
    width: 100%;
    cursor: pointer;
    &:hover {
      background-color: #f5f7fa;
    }
    .docs_list_item_name {
      color: #606266;
      display: block;
      margin-right: 40px;
      overflow: hidden;
      text-overflow: ellipsis;
      transition: color .3s;
      padding-left: 5px;
      white-space: nowrap;
      & > i {
        height: 100%;
        margin-right: 7px;
        color: #909399;
        line-height: inherit;
      }
    }
  }
  .docs_list_item+.docs_list_item {
    margin-top: 5px;
  }
}
</style>
