// 省份列表
import cities from '../assets/json/cities.json'

// 省份列表
const provinceList = cities.map(item => item.label)
provinceList.unshift('不限')

// 非法人入驻 -> 服务方向
const fwfx = ['技术转移', '科技咨询', '科技金融', '研究开发', '知识产权', '检验检测认证', '创业孵化', '科学技术普及', '综合科技服务']

// 非法人入驻 -> 组织类别
const zzlb = ['企业法人', '事业法人', '社团法人', '其他']

// 非法人入驻 -> 身份类别
const sflb = ['技术经纪人', '创新创业导师', '技术专家', '其他']

// 专家团队 -> 服务类型
const fwlx = ['技术服务', '企业管理', '创业指导', '科研创新', '其他']

// 成果 -> 成果类型
const cglx = ['发明专利', '实用新型专利', '软件著作权', '著作权', '商标权', '外观设计', '植物新品种', '其他技术成果']

// 成果 -> 成熟度
const csd = ['报告级', '方案级', '功能级', '仿真级', '初样级', '正样级', '环境级', '产品级', '系统级', '销售级']

// 成果 -> 合作方式
const hzfs = ['技术转让', '专利许可', '委托开发', '合作开发', '技术咨询', '技术服务', '技术入股', '创业融资', '股权融资']

// 发布需求 -> 需求类别
const xqlb = ['研究开发', '技术转移', '检验检测', '创业孵化', '科技金融', '知识产权', '科技咨询', '科学技术普及服务', '综合科技服务']

// 发布需求 -> 企业规模
const qygm = ['大型', '中型', '小型', '微型']

// 发布需求 -> 企业性质
const qyxz = ['国有', '集体', '私营', '其他']

export default {
  cities,
  provinceList,
  fwlx,
  fwfx,
  zzlb,
  sflb,
  cglx,
  csd,
  hzfs,
  xqlb,
  qygm,
  qyxz
}
