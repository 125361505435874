
const globalVar = {
  /**
   * 前台tokenName
   */
  WEB_TOKEN: 'serviceWebToken',

  /**
   * 前台用户性别-未知
   */
  USER_GENDER_0: 0,
  /**
   * 前台用户性别-男
   */
  USER_GENDER_1: 1,
  /**
   * 前台用户性别-女
   */
  USER_GENDER_2: 2,
  /**
   * 服务状态-审核中
   */
  SERVICE_STATUS_0: 0,
  /**
   * 服务状态-已通过
   */
  SERVICE_STATUS_1: 1,
  /**
   * 服务状态-已驳回
   */
  SERVICE_STATUS_2: 2,
  /**
   * 服务状态-已下架
   */
  SERVICE_STATUS_3: 3,
  /**
   * 成果状态-待审核
  */
  ACHIEVEMENT_STATUS_0: 0,
  /**
  * 成果状态-已通过
  */
  ACHIEVEMENT_STATUS_1: 1,
  /**
  * 成果状态-已驳回
  */
  ACHIEVEMENT_STATUS_2: 2,
  /**
  * 成果状态-已下架
  */
  ACHIEVEMENT_STATUS_3: 3,
  /**
   * 订单状态-已确认
   */
  ORDER_STATUS_3: 3,
  /**
   * 订单状态-已取消
   */
  ORDER_STATUS_4: 4,
  /**
   * 专家状态-待审核
   */
  EXPERT_STATUS_0: 0,
  /**
    * 专家状态-已通过
    */
  EXPERT_STATUS_1: 1,
  /**
    * 专家状态-已驳回
    */
  EXPERT_STATUS_2: 2,
  /**
    * 专家状态-已下架
    */
  EXPERT_STATUS_3: 3,
  /**
   * 订单状态-已下单
   */
  ORDER_STATUS_0: 0,
  /**
   * 订单状态-进行中
   */
  ORDER_STATUS_1: 1,
  /**
   * 订单状态-已完成
   */
  ORDER_STATUS_2: 2,
  /**
   * 需求订单状态-对接中
   */
  REQUIRE_ORDER_STATUS_1: 1,
  /**
   * 需求订单状态-已完成
   */
  REQUIRE_ORDER_STATUS_2: 2
}

/**
 * 法人机构或非法人机构入驻审核状态
 */
const orgRegisterStatus = {
  /**
   * 法人机构或非法人机构入驻审核状态:未申请
   */
  REGISTER_STATUS_NOTAPPLY: 0,
  /**
   * 法人机构或非法人机构入驻审核状态:待处理
   */
  REGISTER_STATUS_PENDING: 1,
  /**
   * 法人机构或非法人机构入驻审核状态:已通过
   */
  REGISTER_STATUS_PASS: 2,
  /**
   * 法人机构或非法人机构入驻审核状态:已驳回
   */
  REGISTER_STATUS_REJECT: 3
}

/**
 * 挂靠审核状态
 */
const dependStatus = {
  /**
   * 挂靠审核状态:未申请
   */
  DEPEND_STATUS_NOTAPPLY: 0,
  /**
  * 挂靠审核状态:待处理
  */
  DEPEND_STATUS_PENDING: 1,
  /**
  * 挂靠审核状态:已通过
  */
  DEPEND_STATUS_PASS: 2,
  /**
  * 挂靠审核状态:已驳回
  */
  DEPEND_STATUS_REJECT: 3
}

/**
 * 服务集成商认证审核状态
 */
const certificationStatus = {
  /**
   * 服务集成商认证审核状态:未申请
   */
  CERTIFICATION_STATUS_NOTAPPLY: 0,
  /**
  * 服务集成商认证审核状态:待处理
  */
  CERTIFICATION_STATUS_PENDING: 1,
  /**
  * 服务集成商认证审核状态:已通过
  */
  CERTIFICATION_STATUS_PASS: 2,
  /**
  * 服务集成商认证审核状态:已驳回
  */
  CERTIFICATION_STATUS_REJECT: 3
}

/**
 * 机构角色
 *  + 机构入驻成为服务用户时 是法人还是挂靠
 *  + 服务集成商认证时 之前服务用户是法人还是挂靠
 */
const organizationRole = {
  /**
   * 挂靠关系
   */
  ORG_ROLE_DEPEND: 0,
  /**
   * 法人关系
   */
  ORG_ROLE_CORPORATE: 1
}

/**
 * 用户角色
 */
const userRoles = {
  /**
   * 前台用户-普通用户
   */
  USER_ROLE_0: 0,
  /**
   * 前台用户-服务用户
   */
  USER_ROLE_1: 1,
  /**
   * 前台用户-服务集成商
   */
  USER_ROLE_2: 2
}

/**
 * 需求状态
 */
const requireStatus = {
  /**
   * 已取消
   */
  REQUIRE_STATUS_CANCELLED: 0,
  /**
   * 已完成
   */
  REQUIRE_STATUS_COMPLETED: 1,
  /**
   * 已提交
   */
  REQUIRE_STATUS_SUBMITTED: 2,
  /**
   * 待处理
   */
  REQUIRE_STATUS_PENDING: 3,
  /**
   * 已接受
   */
  REQUIRE_STATUS_RECEIVED: 4,
  /**
   * 已确认
   */
  REQUIRE_STATUS_CONFIRMED: 5,
  /**
   * 已报价
   */
  REQUIRE_STATUS_QUOTED: 6,
  /**
   * 代理中
   */
  REQUIRE_STATUS_ACTING: 7,
  /**
   * 对接中
   */
  REQUIRE_STATUS_DOCKING: 8,
  /**
   * 已发布
   */
  REQUIRE_STATUS_PUBLISHED: 9
}

/**
 * 需求的options
 */
const REQUIRE_STATUS_OPTIONS = [
  { label: '已提交', value: requireStatus.REQUIRE_STATUS_SUBMITTED },
  { label: '已发布', value: requireStatus.REQUIRE_STATUS_PUBLISHED },
  { label: '待处理', value: requireStatus.REQUIRE_STATUS_PENDING },
  { label: '已接受', value: requireStatus.REQUIRE_STATUS_RECEIVED },
  { label: '已确认', value: requireStatus.REQUIRE_STATUS_CONFIRMED },
  { label: '已报价', value: requireStatus.REQUIRE_STATUS_QUOTED },
  { label: '代理中', value: requireStatus.REQUIRE_STATUS_ACTING },
  { label: '对接中', value: requireStatus.REQUIRE_STATUS_DOCKING },
  { label: '已完成', value: requireStatus.REQUIRE_STATUS_COMPLETED },
  { label: '已取消', value: requireStatus.REQUIRE_STATUS_CANCELLED }
]

/**
 * 资讯状态
 */
const infoStatus = {
  /**
   * 待审核
   */
  INFO_STATUS_TOCHECK: 0,
  /**
   * 已发布
   */
  INFO_STATUS_PUBLISHED: 1,
  /**
   * 已驳回
   */
  INFO_STATUS_FAILED: 2
}
/**
 * 资讯的options
 */
const INFO_STATUS_OPTIONS = [
  { label: '待审核', value: infoStatus.INFO_STATUS_TOCHECK },
  { label: '已发布', value: infoStatus.INFO_STATUS_PUBLISHED },
  { label: '已驳回', value: infoStatus.INFO_STATUS_FAILED }
]

export default {
  ...globalVar,
  ...orgRegisterStatus,
  ...dependStatus,
  ...certificationStatus,
  ...organizationRole,
  ...userRoles,
  ...requireStatus,
  ...infoStatus,
  REQUIRE_STATUS_OPTIONS,
  INFO_STATUS_OPTIONS
}
