import Cookies from 'js-cookie'

const domain = process.env.VUE_APP_COOKIE_DOMAIN

const config = {
  domain: domain
}

export default {
  domain,
  get (key) {
    return Cookies.get(key, config)
  },
  set (key, value) {
    Cookies.set(key, value, Object.assign({ expires: 1 }, config))
  },
  remove (key) {
    Cookies.remove(key, config)
  }
}
