var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"info_item"},[_c('p',{staticClass:"info_item_label",style:({
      width: _vm.labelWidth
    })},[_vm._v(" "+_vm._s(_vm.label)+" ")]),(_vm.valueType === 'text')?[_c('p',{staticClass:"info_item_value"},[_vm._t("default",[_vm._v(_vm._s(_vm.value))])],2)]:_vm._e(),(_vm.valueType === 'image')?[_c('el-image',{style:({
        width: _vm.imageWidth,
        height: _vm.imageHeight
      }),attrs:{"src":_vm.value,"fit":_vm.imageFit}})]:_vm._e(),(_vm.valueType === 'image-list')?[_c('div',{staticClass:"images"},_vm._l((_vm.value),function(src,index){return _c('div',{key:index,staticClass:"images_item"},[_c('el-image',{style:({
            width: _vm.imageWidth,
            height: _vm.imageHeight
          }),attrs:{"src":src,"preview-src-list":_vm.value,"fit":_vm.imageFit}})],1)}),0)]:_vm._e(),(_vm.valueType === 'file-list')?[_c('file-list',{attrs:{"list":_vm.value}})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }