import Vue from 'vue'
import Vuex from 'vuex'

import * as api from '@/api'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    /**
     * 角色， 0 - 一般机构 / 1 - 服务机构 / 2 - 管理员
     */
    userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {},
    // 技术领域下拉列表
    technicalList: [],
    // 行业领域下拉列表
    industrySectorList: [],
    // 一级服务类型
    serviceTypeLevel1: []

  },
  mutations: {
    setUserInfo (state, data) {
      state.userInfo = data
      localStorage.setItem('userInfo', JSON.stringify(data))
    },
    // 设置技术领域下拉列表
    setTechnicalList (state, data) {
      state.technicalList = data
    },
    // 设置行业领域下拉列表
    setIndustrySectorList (state, data) {
      state.industrySectorList = data
    },
    // 设置一级服务类型
    setServiceTypeLevel1 (state, data) {
      state.serviceTypeLevel1 = data
    }
  },
  actions: {
    // 获取个人信息
    getUserInfo ({ commit }) {
      return new Promise((resolve, reject) => {
        api.getUserInfo().then(res => {
          if (res.data.code === 0) {
            commit('setUserInfo', res.data.data)
            resolve()
          } else {
            console.error('获取个人信息错误')
          }
        }).catch((err) => {
          reject(err)
        })
      })
    },
    // 获取技术领域下拉框列表
    getTechnicalList ({ commit }) {
      return new Promise((resolve, reject) => {
        api.getTechnicalList().then(res => {
          if (res.data.code === 0) {
            commit('setTechnicalList', res.data.data.list)
            resolve()
          } else {
            console.error('获取技术领域下拉框列表错误')
          }
        }).catch(err => {
          reject(err)
        })
      })
    },
    // 获取行业领域下拉框列表
    getIndustrySectorList ({ commit }) {
      return new Promise((resolve, reject) => {
        api.getIndustrySectorList().then(res => {
          if (res.data.code === 0) {
            commit('setIndustrySectorList', res.data.data.list)
            resolve()
          } else {
            console.error('获取行业领域下拉框列表错误')
          }
        }).catch(err => {
          reject(err)
        })
      })
    },
    // 获取一级服务类型下拉框列表
    getServiceTypeOptions ({ commit }) {
      return new Promise((resolve, reject) => {
        api.getServiceTypeOptions({
          level: 1
        }).then(res => {
          if (res.data.code === 0) {
            commit('setServiceTypeLevel1', res.data.data.list)
            resolve()
          } else {
            console.error('获取一级服务类型下拉框列表错误')
          }
        }).catch(err => {
          reject(err)
        })
      })
    }
  },
  modules: {
  }
})
