import cookie from '@/configs/cookie'
import globalVar from '@/configs/globalVar'
import Vue from 'vue'
import VueRouter from 'vue-router'
import * as api from '@/api'

Vue.use(VueRouter)

const routes = [
  // 登录
  {
    path: '/login',
    component: () => import('../views/login/index.vue')
  },
  // 第三方登录回调页 需带着是哪个第三方和是登录还是绑定
  {
    path: '/third-party-callback/:client/:action',
    component: () => import('../views/third-party-callback/index.vue'),
    props: true
  },
  // 一般机构 -> 非法人(团队)填写入驻表单进行申请
  {
    path: '/org-audit/uncorporate/apply',
    component: () => import('../views/org-audit/uncorporate/apply.vue'),
    meta: {
      requiresAuth: true,
      role: [globalVar.USER_ROLE_0]
    }
  },
  // 一般机构 -> 非法人(团队)填写入驻表单详情
  {
    path: '/org-audit/uncorporate/detail',
    component: () => import('../views/org-audit/uncorporate/detail.vue'),
    meta: {
      requiresAuth: true,
      role: [globalVar.USER_ROLE_0, globalVar.USER_ROLE_1, globalVar.USER_ROLE_2]
    }
  },
  // 一般机构 -> 法人填写入驻表单进行申请
  {
    path: '/org-audit/corporate/apply',
    component: () => import('../views/org-audit/corporate/apply.vue'),
    meta: {
      requiresAuth: true,
      role: [globalVar.USER_ROLE_0]
    }
  },
  // 一般机构 -> 法人填写入驻表单详情
  {
    path: '/org-audit/corporate/detail',
    component: () => import('../views/org-audit/corporate/detail.vue'),
    meta: {
      requiresAuth: true,
      role: [globalVar.USER_ROLE_0, globalVar.USER_ROLE_1, globalVar.USER_ROLE_2]
    }
  },
  // 一般机构 -> 个人挂靠
  {
    path: '/org-audit/person/apply',
    component: () => import('../views/org-audit/person/apply.vue'),
    meta: {
      requiresAuth: true,
      role: [globalVar.USER_ROLE_0]
    }
  },
  // 法人/非法人服务机构 -> 申请为集成商
  {
    path: '/org-audit/aggregator-admin/apply',
    component: () => import('../views/org-audit/aggregator-admin/apply.vue'),
    meta: {
      requiresAuth: true,
      role: [globalVar.USER_ROLE_1, globalVar.USER_ROLE_2]
    }
  },
  // 法人/非法人服务机构 -> 申请为集成商表单详情
  {
    path: '/org-audit/aggregator-admin/detail',
    component: () => import('../views/org-audit/aggregator-admin/detail.vue'),
    meta: {
      requiresAuth: true,
      role: [globalVar.USER_ROLE_1, globalVar.USER_ROLE_2]
    }
  },
  // 一般用户/挂靠来的服务商 -> 申请为集成商
  {
    path: '/org-audit/aggregator-person/apply',
    component: () => import('../views/org-audit/aggregator-person/apply.vue'),
    meta: {
      requiresAuth: true,
      role: [globalVar.USER_ROLE_1, globalVar.USER_ROLE_2]
    }
  },
  // 一般用户/挂靠来的服务商 -> 申请为集成商表单详情
  {
    path: '/org-audit/aggregator-person/detail',
    component: () => import('../views/org-audit/aggregator-person/detail.vue'),
    meta: {
      requiresAuth: true,
      role: [globalVar.USER_ROLE_1, globalVar.USER_ROLE_2]
    }
  },
  // 修改密码
  {
    path: '/change-password',
    component: () => import('../views/change-password/index.vue'),
    meta: {
      requiresAuth: true,
      role: [globalVar.USER_ROLE_0, globalVar.USER_ROLE_1, globalVar.USER_ROLE_2]
    }
  },
  // 资讯详情
  {
    path: '/info-detail/:id',
    component: () => import('../views/info-detail'),
    meta: {
      requiresAuth: true,
      role: [globalVar.USER_ROLE_0, globalVar.USER_ROLE_1, globalVar.USER_ROLE_2]
    }
  },
  // 编辑机构详情
  {
    path: '/org-edit/:id',
    component: () => import('../views/org-detail/org-edit.vue'),
    meta: {
      requiresAuth: true,
      role: [globalVar.USER_ROLE_1, globalVar.USER_ROLE_2]
    }
  },
  // 服务订单详情
  {
    path: '/service-order/order-detail/:id',
    component: () => import('../views/service-order/order-detail'),
    meta: {
      requiresAuth: true,
      role: [globalVar.USER_ROLE_0, globalVar.USER_ROLE_1, globalVar.USER_ROLE_2]
    }
  },
  // 需求订单详情
  {
    path: '/require-order/order-detail/:id',
    component: () => import('../views/require-order/order-detail'),
    meta: {
      requiresAuth: true,
      role: [globalVar.USER_ROLE_0, globalVar.USER_ROLE_1, globalVar.USER_ROLE_2]
    }
  },
  // 一般机构发布需求
  {
    path: '/require-create',
    component: () => import('../views/require-manage/require-create'),
    meta: {
      requiresAuth: true,
      role: [globalVar.USER_ROLE_0, globalVar.USER_ROLE_1]
    }
  },
  // 服务集成商机构发布需求
  {
    path: '/require-create/service-org',
    component: () => import('../views/require-manage/require-create/service-org'),
    meta: {
      requiresAuth: true,
      role: [globalVar.USER_ROLE_2]
    }
  },
  // 发布需求成功
  {
    path: '/require-create-success',
    component: () => import('../views/require-manage/create-success/index.vue'),
    meta: {
      requiresAuth: true,
      role: [globalVar.USER_ROLE_0, globalVar.USER_ROLE_1, globalVar.USER_ROLE_2]
    }
  },
  // 需求详情
  {
    path: '/require-detail-form/:id',
    component: () => import('../views/require-manage/require-detail/index.vue'),
    meta: {
      requiresAuth: true,
      role: [globalVar.USER_ROLE_0, globalVar.USER_ROLE_1, globalVar.USER_ROLE_2]
    }
  },
  // 发布服务
  {
    path: '/service-create',
    component: () => import('../views/service-manage/service-create/index.vue'),
    meta: {
      requiresAuth: true,
      role: [globalVar.USER_ROLE_1, globalVar.USER_ROLE_2]
    }
  },
  // 服务详情
  {
    path: '/service-detail-readonly/:id',
    component: () => import('../views/service-manage/service-detail/index.vue'),
    meta: {
      requiresAuth: true,
      role: [globalVar.USER_ROLE_1, globalVar.USER_ROLE_2]
    }
  },
  // 发布服务成功
  {
    path: '/service-create-success',
    component: () => import('../views/service-manage/create-success/index.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      role: [globalVar.USER_ROLE_1, globalVar.USER_ROLE_2]
    }
  },
  // 发布服务链
  {
    path: '/service-chain/:type/:id?',
    component: () => import('../views/service-manage/service-chain'),
    meta: {
      requiresAuth: true,
      role: [globalVar.USER_ROLE_2]
    }
  },
  // 成员详情 / 挂靠详情
  {
    path: '/member-detail/:id',
    component: () => import('../views/member-manage/member-detail'),
    meta: {
      requiresAuth: true,
      role: [globalVar.USER_ROLE_0, globalVar.USER_ROLE_1, globalVar.USER_ROLE_2]
    }
  },
  // 新增专家
  // {
  //   path: '/expert-create',
  //   component: () => import('../views/expert-manage/expert-create'),
  //   meta: {
  //     requiresAuth: true,
  //     role: [globalVar.USER_ROLE_0, globalVar.USER_ROLE_1, globalVar.USER_ROLE_2]
  //   }
  // },
  // // 专家详情
  // {
  //   path: '/expert-detail/:id',
  //   component: () => import('../views/expert-manage/expert-detail'),
  //   meta: {
  //     requiresAuth: true,
  //     role: [globalVar.USER_ROLE_0, globalVar.USER_ROLE_1, globalVar.USER_ROLE_2]
  //   }
  // },
  // 新增成果
  {
    path: '/achievement-create',
    component: () => import('../views/achievement-manage/achievement-create'),
    meta: {
      requiresAuth: true,
      role: [globalVar.USER_ROLE_0, globalVar.USER_ROLE_1, globalVar.USER_ROLE_2]
    }
  },
  // 成果详情
  {
    path: '/achievement-detail-readonly/:id',
    component: () => import('../views/achievement-manage/achievement-detail'),
    meta: {
      requiresAuth: true,
      role: [globalVar.USER_ROLE_0, globalVar.USER_ROLE_1, globalVar.USER_ROLE_2]
    }
  },
  // 电子签名
  {
    path: '/signature/:rId/:uId',
    component: () => import('../views/signature')
  },
  // 在线沟通
  {
    path: '/chat',
    component: () => import('../views/chat'),
    meta: {
      role: [globalVar.USER_ROLE_0, globalVar.USER_ROLE_1, globalVar.USER_ROLE_2]
    }
  },
  {
    path: '/',
    component: () => import('../views/layout'),
    meta: {
      requiresAuth: true
    },
    redirect: to => {
      // const userInfo = JSON.parse(localStorage.getItem('userInfo')) || {}
      /* return {
        [globalVar.USER_ROLE_0]: '/resource-search',
        [globalVar.USER_ROLE_1]: '/index',
        [globalVar.USER_ROLE_2]: '/index'
      }[userInfo.role] || '/login' */

      // 暂且全部跳转用户中心
      return '/personal-info'
    },
    children: [
      // 服务机构-首页
      {
        path: 'index',
        component: () => import('../views/index'),
        meta: {
          role: [globalVar.USER_ROLE_1, globalVar.USER_ROLE_2]
        }
      },
      // 资源大厅
      {
        path: 'resource-search',
        component: () => import('../views/resource-search'),
        meta: {
          role: [globalVar.USER_ROLE_0, globalVar.USER_ROLE_1, globalVar.USER_ROLE_2]
        }
      },
      // 服务详情
      {
        path: 'service-detail/:id',
        component: () => import('../views/resource-detail/service'),
        meta: {
          role: [globalVar.USER_ROLE_0, globalVar.USER_ROLE_1, globalVar.USER_ROLE_2]
        }
      },
      // 服务链详情
      {
        path: 'service-chain-detail/:id',
        component: () => import('../views/resource-detail/service-chain'),
        meta: {
          role: [globalVar.USER_ROLE_0, globalVar.USER_ROLE_1, globalVar.USER_ROLE_2]
        }
      },
      // 机构详情
      {
        path: 'org-detail/:id',
        component: () => import('../views/org-detail'),
        meta: {
          role: [globalVar.USER_ROLE_0, globalVar.USER_ROLE_1, globalVar.USER_ROLE_2]
        }
      },
      // 需求详情
      {
        path: 'require-detail/:id',
        component: () => import('../views/resource-detail/require'),
        meta: {
          role: [globalVar.USER_ROLE_0, globalVar.USER_ROLE_1, globalVar.USER_ROLE_2]
        }
      },
      // 专利详情
      {
        path: 'patent-detail/:id',
        component: () => import('../views/resource-detail/patent'),
        meta: {
          role: [globalVar.USER_ROLE_0, globalVar.USER_ROLE_1, globalVar.USER_ROLE_2]
        }
      },
      // 论文详情
      {
        path: 'paper-detail/:id',
        component: () => import('../views/resource-detail/paper'),
        meta: {
          role: [globalVar.USER_ROLE_0, globalVar.USER_ROLE_1, globalVar.USER_ROLE_2]
        }
      },
      // 成果详情
      {
        path: 'achievement-detail/:id',
        component: () => import('../views/resource-detail/cg'),
        meta: {
          role: [globalVar.USER_ROLE_0, globalVar.USER_ROLE_1, globalVar.USER_ROLE_2]
        }
      },
      // 个人中心
      {
        path: 'personal-info',
        component: () => import('../views/personal-info'),
        meta: {
          role: [globalVar.USER_ROLE_0, globalVar.USER_ROLE_1, globalVar.USER_ROLE_2]
        }
      },
      // 服务订单
      {
        path: 'service-order',
        component: () => import('../views/service-order'),
        meta: {
          role: [globalVar.USER_ROLE_0, globalVar.USER_ROLE_1, globalVar.USER_ROLE_2]
        }
      },
      // 需求订单
      {
        path: 'require-order',
        component: () => import('../views/require-order'),
        meta: {
          role: [globalVar.USER_ROLE_0, globalVar.USER_ROLE_1, globalVar.USER_ROLE_2]
        }
      },
      // 服务管理
      {
        path: 'service-manage',
        component: () => import('../views/service-manage'),
        meta: {
          role: [globalVar.USER_ROLE_1, globalVar.USER_ROLE_2]
        }
      },
      // 需求管理
      {
        path: 'require-manage',
        component: () => import('../views/require-manage'),
        meta: {
          role: [globalVar.USER_ROLE_0, globalVar.USER_ROLE_1, globalVar.USER_ROLE_2]
        }
      },
      // 成员列表
      {
        path: '/member-list',
        component: () => import('../views/member-manage/member-list'),
        meta: {
          role: [globalVar.USER_ROLE_1, globalVar.USER_ROLE_2]
        }
      },
      // 挂靠审核
      {
        path: '/gk-audit',
        component: () => import('../views/member-manage/gk-audit'),
        meta: {
          role: [globalVar.USER_ROLE_1, globalVar.USER_ROLE_2]
        }
      },
      // 专家团队
      // {
      //   path: '/expert-manage',
      //   component: () => import('../views/expert-manage'),
      //   meta: {
      //     role: [globalVar.USER_ROLE_0, globalVar.USER_ROLE_1, globalVar.USER_ROLE_2]
      //   }
      // },
      // 成果管理
      {
        path: '/achievement-manage',
        component: () => import('../views/achievement-manage'),
        meta: {
          role: [globalVar.USER_ROLE_0, globalVar.USER_ROLE_1, globalVar.USER_ROLE_2]
        }
      },
      // 资讯管理
      {
        path: '/info-manage',
        component: () => import('../views/info-manage'),
        meta: {
          role: [globalVar.USER_ROLE_1, globalVar.USER_ROLE_2]
        }
      }
    ]
  },
  // 资讯管理 新建 -> 编辑
  {
    path: '/info-manage/info-form/:id?',
    component: () => import('../views/info-manage/info-form.vue'),
    props: true,
    meta: {
      role: [globalVar.USER_ROLE_1, globalVar.USER_ROLE_2]
    }
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    const token = cookie.get(globalVar.WEB_TOKEN)
    if (!token) {
      next({
        path: '/login'
      })
    } else {
      const userinfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {}
      const role = userinfo.role
      // 没有role或者有权限，则通过
      if (to.meta.role && !to.meta.role.includes(role)) {
        next('/login')
      } else {
        next()
      }
    }
  } else {
    next() // 确保一定要调用 next()
  }
})

router.afterEach((to, from) => {
  api.pageActivity()
})

export default router
